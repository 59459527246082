<template>
  <div class="min-h-screen flex flex-col">
    <app-header></app-header>

    <div class="mt-20 flex-grow h-full">
      <router-view></router-view>
    </div>
    <app-footer></app-footer>
  </div>
</template>
<script>
export default {
  name: "FullLayout"
};
</script>
<style lang="postcss" scoped>
body {
  @apply pt-16;
}
</style>
